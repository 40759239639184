import { Button, Grid, Paper, Stack, Typography } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { headerTable } from "functions/Static";
import DatePicker from "react-date-picker";
import TableCommission from "./TableCommission";
import ChartCommission from "./ChartCommission";

const ListIBCommission = (props) => {
    const { data, profile, language, page, handleChangePage, state, submitSearch, handleDateStartChange, handleDateEndChange } = props;
    const count = Math.ceil(data.length > 0 && data.length / 10);
    const setPage = page - 1;
    const dataList = data.slice(setPage * 10, page * 10);
    const dataMoney = data.filter((e) => e.type === 1);

    return (
        <Grid container rowSpacing={5} justifyContent="center" alignItems="center" mt={5}>
            <Grid item xs={12}>
                <Typography sx={{ fontSize: 24, fontWeight: 600, color: "#000", borderBottom: "solid 2px #4589C6" }}>
                    {language === "th" ? "Rebate" : "Rebate"}
                </Typography>
                <Stack direction="row" alignItems="center" mt={2}>
                    <Typography sx={{ fontSize: 20, fontWeight: 600, color: "#000", mr: 2, display: { xs: "none", md: "block" } }}>
                        {language === "th" ? "ค้นหา" : "Search"}:
                    </Typography>
                    <Grid container xs={12} md={12} lg={10} sx={{ mr: 2 }} spacing={1}>
                        <Grid item xs={12} md={3}>
                            <DatePicker className={"date-picker"} onChange={handleDateStartChange} value={state.date_start} format="dd/MM/yyyy" />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <DatePicker className={"date-picker"} onChange={handleDateEndChange} value={state.date_end} format="dd/MM/yyyy" />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Button
                                variant="contained"
                                style={{ backgroundColor: "#4589C6" }}
                                //  className="btn-style"
                                sx={{ maxWidth: 120, fontSize: 16, width: "100%", borderRadius: 40, height: 40, display: { xs: "none", md: "block" } }}
                                onClick={submitSearch}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        submitSearch();
                                    }
                                }}
                            >
                                {language === "th" ? "ค้นหา" : "Search"}
                            </Button>
                        </Grid>
                    </Grid>
                    <Button
                        variant="contained"
                        color="error"
                        sx={{ maxWidth: 50, fontSize: 16, width: "100%", borderRadius: 40, height: 40, display: { xs: "block", md: "none" } }}
                        onClick={submitSearch}
                    >
                        <SearchIcon />
                    </Button>
                </Stack>
            </Grid>
            <Grid item xs={12}>
                <ChartCommission data={dataMoney} />
            </Grid>
            <Grid item xs={12}>
                <TableCommission head={headerTable.iblist_commission} body={dataList} count={count} page={page} handleChangePage={handleChangePage} profile={profile} language={language} />
            </Grid>
        </Grid>
    );
};

export default ListIBCommission;
