import React from "react";
import { withRouter, useParams, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import {
     Grid,
     Typography,
     Button,
     Radio,
     FormControlLabel,
     Checkbox,
     TextField,
     InputAdornment,
     MenuItem,
     Stack,
     Pagination,
     CardMedia,
     CircularProgress,
     RadioGroup,
     FormControl,
     FormLabel,
     Select,
     List,
     ListItem,
     ListItemButton,
     Box,
     IconButton,
} from "@material-ui/core";
import { headerTable, textFiled } from "functions/Static";
import { GridTextField } from "components";
import TableDeposit from "../tabledeposit/TableDeposit";
import { styled } from "@material-ui/styles";
import slip from "../../../assets/logo/slip.png";
import SearchIcon from "@material-ui/icons/Search";
import { createMuiTheme } from "@material-ui/core/styles";
import CardHeadFinancial from "../CardHead/CardHeadFinancial";
// import logo_web from "../../assets/logo/LOGOQrs.png";
import ERC20_qr from "../../../assets/logo/ERC20.png";
import TRC20_qr from "../../../assets/logo/TRC20.png";
import copy from "copy-to-clipboard";

//icon
// import {ContentCopyIcon} from '@mui/icons-material/ContentCopy';

const theme = createMuiTheme();
// import { ip } from "../../../service";

const address_data = [
     {
          label: 'ERC20',
          value: '0x9ec9c4f5db28bfad15d70f2d2cc54eb04b2ef4e5'
     },
     {
          label: 'TRC20',
          value: 'TRKfcGoSE4mWVX6KJbFGepGRaMucT7C2qM'
     }
]
const currencies = [
     {
          value: "thb",
          label: "Thailand Baht",
     },
     // {
     //      value: "usdt",
     //      label: "USDT",
     // },
];

const s_usdt = [
     {
          value: "usdt",
          label: "USDT",
     },
];
const internet_bank = [
     {
          value: "kbank",
          label: "K Bank",
          img: "https://www.kasikornbank.com/SiteCollectionDocuments/business/sme/digital-banking/kplusshop/img/logo-mainweb.png",
     },
];

const WebDeposit = (props) => {

     const location = useLocation();
     let path = location.state;

     const params = useParams();
     const classes = useStyles();
     const {
          data,
          language,
          page,
          state,
          handleChange,
          handleChangeAgree,
          handleChangePage,
          slip_upload,
          handleChangeText,
          onClickSubmitDeposit,
          onClickViewSlip,
          handleCloseModal,
          profile,
          radio_finance,
          payment,
          submitSearch,
          handleSearch,
          mindeposit_error,
          maxdeposit_error,
          min_deposit,
          max_deposit,
          onClickStatus,
          info,
          handleChangeMt4,
          handleChangeImage,
          select_image,
          uploadSlipUSDT,
          getPathPassState,
     } = props;
     const count = Math.ceil(data.length > 0 && data.length / 10);
     const setPage = page - 1;
     const dataList = data.slice(setPage * 10, page * 10);
     // --------------------------------Timer-----------------------------------
     const [countDown, setCountDown] = React.useState(0);
     const [runTimer, setRunTimer] = React.useState(false);

     React.useEffect(() => {
          getPathPassState(path);
     }, []);

     React.useEffect(() => {
          if (state.status_transaction === 2) {
               setRunTimer(true);
          } else if (state.status_transaction === 1) {
               setRunTimer(false);
          }
     });

     React.useEffect(() => {
          let timerId;
          if (runTimer) {
               setCountDown(60 * 5);
               timerId = setInterval(() => {
                    setCountDown((countDown) => countDown - 1);
               }, 1000);
          } else {
               clearInterval(timerId);
          }
          return () => clearInterval(timerId);
     }, [runTimer]);

     React.useEffect(() => {
          if (countDown < 0 && runTimer) {
               console.log("expired");
               setRunTimer(false);
               setCountDown(0);
               window.location.reload();
          }
     }, [countDown, runTimer]);

     // const togglerTimer = () => setRunTimer((t) => !t);

     const seconds = String(countDown % 60).padStart(2, 0);
     const minutes = String(Math.floor(countDown / 60)).padStart(2, 0);

     const [value, setValue] = React.useState("");
     const [valueCrypto, setCrypto] = React.useState("");
     const [address, setAddress] = React.useState("");
     const [textC, setTextC] = React.useState("");
     const [accSelect, setAccSelect] = React.useState("");

     const handleRadioChange = (event) => {
          setValue(event.target.value);
     };

     const handleSubmit = () => {
          if (value === "TRC20") {
               setCrypto(TRC20_qr);
               setAddress("TRKfcGoSE4mWVX6KJbFGepGRaMucT7C2qM")
               setTextC("TRC20")
          } else if (value === "ERC20") {
               setCrypto(ERC20_qr);
               setAddress("0x9ec9c4f5db28bfad15d70f2d2cc54eb04b2ef4e5")
               setTextC("ERC20")
          } else {
          }
          console.log("value", value);
     };

     return (
          <Grid item container spacing={2}>
               <Grid item xs={12}>
                    <Typography className={classes.fontStyleTitle}>
                         Deposit - Available Funds in Wallet: $ {profile.wallet ? Math.floor(profile.wallet * 100) / 100 : "0.00"}
                    </Typography>
               </Grid>
               {/* <CardHeadFinancial language={language} /> */}
               <Grid item xs={12} container direction="row" spacing={2}>
                    <Grid item xs={12} md={4} container spacing={2} alignContent={"flex-start"}>
                         <Grid item xs={12}>
                              <Typography className={classes.fontStyleTitleBorder}>{language === "th" ? "ช่องทางการชำระเงิน" : "Payment Gateway"}</Typography>
                         </Grid>
                         {payment &&
                              payment.map((el, i) => {
                                   return (
                                        <>
                                             <Grid key={i} item xs={12} container className={classes.gridRadio} marginLeft={2} maxHeight={100}>
                                                  <Grid xs={12} container item justifyContent="space-between">
                                                       <Grid xs={8} container item alignContent="center">
                                                            <FormControlLabel
                                                                 sx={{ alignSelf: "center", marginBottom: 2 }}
                                                                 value={el.payment_gateway_id}
                                                                 name={"type"}
                                                                 label={el.payment_name}
                                                                 checked={Number(state.type) === el.payment_gateway_id}
                                                                 onChange={handleChange}
                                                                 control={<Radio />}
                                                            />
                                                       </Grid>
                                                       <Grid xs={4} container item justifyContent="flex-end">
                                                            {el.img && <CardMedia className={classes.iconStyle} component="img" alt={el.name} image={el.img} />}
                                                       </Grid>
                                                  </Grid>

                                                  {el.payment_gateway_id === 1 && (
                                                       <Grid xs={12} marginBottom={2} paddingRight={2}>
                                                            <TextField
                                                                 className="textfield-profile"
                                                                 select
                                                                 fullWidth
                                                                 size="small"
                                                                 type="text"
                                                                 value={state.bank}
                                                                 placeholder="Currency"
                                                            >
                                                                 {internet_bank.map((option) => (
                                                                      <MenuItem key={option.value} value={option.value}>
                                                                           <Grid xs={12} container justifyContent="space-between" alignItems="center">
                                                                                <Typography>{option.label}</Typography>
                                                                                <CardMedia className={classes.iconStyle} component="img" alt={option.label} image={option.img} />
                                                                           </Grid>
                                                                      </MenuItem>
                                                                 ))}
                                                            </TextField>
                                                       </Grid>
                                                  )}
                                             </Grid>
                                             {/*////////////////// USDT/////////////// */}
                                             <Grid key={i} item xs={12} container className={classes.gridRadio} marginLeft={2} maxHeight={100}>
                                                  <Grid xs={12} container item justifyContent="space-between">
                                                       <Grid xs={8} container item alignContent="center">
                                                            <FormControlLabel
                                                                 sx={{ alignSelf: "center", marginBottom: 2 }}
                                                                 value={5}
                                                                 name={"type"}
                                                                 label={"USDT"}
                                                                 checked={Number(state.type) === 5}
                                                                 onChange={handleChange}
                                                                 control={<Radio />}
                                                            />
                                                       </Grid>
                                                  </Grid>

                                                  {el.payment_gateway_id === 1 && (
                                                       <Grid xs={12} marginBottom={2} paddingRight={2}>
                                                            <TextField
                                                                 className="textfield-profile"
                                                                 select
                                                                 fullWidth
                                                                 size="small"
                                                                 type="text"
                                                                 value={state.bank}
                                                                 placeholder="Currency"
                                                            >
                                                                 {internet_bank.map((option) => (
                                                                      <MenuItem key={option.value} value={option.value}>
                                                                           <Grid xs={12} container justifyContent="space-between" alignItems="center">
                                                                                <Typography>{option.label}</Typography>
                                                                                <CardMedia className={classes.iconStyle} component="img" alt={option.label} image={option.img} />
                                                                           </Grid>
                                                                      </MenuItem>
                                                                 ))}
                                                            </TextField>
                                                       </Grid>
                                                  )}
                                             </Grid>
                                        </>
                                   );
                              })}
                    </Grid>

                    <Grid item xs={12} md={8} container spacing={2} alignContent="flex-start">
                         <Grid item xs={12}>
                              <Typography className={classes.fontStyleTitleBorder}>{language === "th" ? "สกุลเงิน" : "Currency"}</Typography>
                         </Grid>
                         <Grid item container xs={10} spacing={2}>
                              <Grid item className="typography-profile pt-0" xs={4}>
                                   <Typography>{language === "th" ? "สกุลเงิน" : "Currency"}</Typography>
                              </Grid>
                              <Grid item xs={8}>
                                   <TextField
                                        className="textfield-profile"
                                        select
                                        fullWidth
                                        size="small"
                                        tyep="text"
                                        value={state.type === 5 ? "usdt" : state.currency}
                                        placeholder={language === "th" ? "สกุลเงิน" : "Currency"}
                                        onChange={handleChangeText}
                                   >
                                        {state.type === 5
                                             ? s_usdt.map((option) => (
                                                  <MenuItem key={option.value} value={option.value}>
                                                       {option.label}
                                                  </MenuItem>
                                             ))
                                             : currencies.map((option) => (
                                                  <MenuItem key={option.value} value={option.value}>
                                                       {option.label}
                                                  </MenuItem>
                                             ))}
                                        {/* // {currencies.map((option) => (
                                        //      <MenuItem key={option.value} value={option.value}>
                                        //           {option.label}
                                        //      </MenuItem>
                                        // ))} */}
                                   </TextField>
                              </Grid>
                              {state.type === 4 &&
                                   <>
                                        <Grid item className="typography-profile pt-0" xs={4}>
                                             <Typography>{language === "th" ? "ไปยัง" : "To"}</Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                             <FormControl fullWidth>
                                                  <Select
                                                       placeholder="Select Account"
                                                       defaultValue={path ? path : 0}
                                                       value={info.value}
                                                       id="account"
                                                       size="small"
                                                       onChange={handleChangeMt4}
                                                  >
                                                       {info.map((option) => (
                                                            <MenuItem key={option.value} value={option.value} >
                                                                 {option.label}
                                                            </MenuItem>
                                                       ))}
                                                  </Select>
                                             </FormControl>
                                        </Grid>
                                        <Grid item className="typography-profile pt-0" xs={4}>
                                             <Typography>{language === "th" ? "อัตราการฝาก" : "Deposit Rate"}</Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                             <TextField
                                                  className="textfield-profile"
                                                  InputProps={{
                                                       endAdornment: <InputAdornment position="end">$</InputAdornment>,
                                                  }}
                                                  fullWidth
                                                  size="small"
                                                  type="number"
                                                  placeholder="0.00"
                                                  name="receive"
                                                  value={(Math.round(state.depositRate * 100) / 100).toFixed(2)}
                                                  onChange={handleChangeText}
                                                  disabled={true}
                                             />
                                        </Grid>
                                   </>
                              }
                              <Grid item className="typography-profile pt-0" xs={4}>
                                   <Typography>{language === "th" ? "เงินฝาก" : "Deposit"}</Typography>
                              </Grid>
                              {state.type === 4 &&
                                   <Grid item xs={8}>
                                        <TextField
                                             // className="textfield-profile"
                                             error={mindeposit_error || maxdeposit_error}
                                             InputProps={{
                                                  endAdornment: <InputAdornment position="end">฿</InputAdornment>,
                                             }}
                                             fullWidth
                                             size="small"
                                             type="number"
                                             placeholder="0.00"
                                             name="deposit"
                                             value={state.deposit}
                                             onChange={handleChangeText}
                                             helperText={
                                                  mindeposit_error
                                                       ? `${language === "th" ? "ฝากขั้นต่ำ" : "Min Deposit is"} ${min_deposit.toLocaleString(undefined, {
                                                            minimumFractionDigits: 2,
                                                            maximumFractionDigits: 2,
                                                       })} THB.`
                                                       : maxdeposit_error
                                                            ? `${language === "th" ? "ฝากได้มากที่สุด" : "Max Deposit is"} ${max_deposit.toLocaleString(undefined, {
                                                                 minimumFractionDigits: 2,
                                                                 maximumFractionDigits: 2,
                                                            })} THB.`
                                                            : ""
                                             }
                                        />
                                   </Grid>
                              }
                              {state.type === 5 &&
                                   <Grid item xs={8}>
                                        <TextField
                                             // className="textfield-profile"
                                             InputProps={{
                                                  endAdornment: <InputAdornment position="end">USDT</InputAdornment>,
                                             }}
                                             fullWidth
                                             size="small"
                                             type="number"
                                             placeholder="0.00"
                                             name="deposit"
                                             value={state.deposit}
                                             onChange={handleChangeText}
                                        />
                                   </Grid>
                              }
                              {state.type === 4 &&
                                   <>
                                        <Grid item className="typography-profile pt-0" xs={4}>
                                             <Typography>{language === "th" ? "เงินรับ" : "Receive"}</Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                             <TextField
                                                  className="textfield-profile"
                                                  InputProps={{
                                                       endAdornment: <InputAdornment position="end">$</InputAdornment>,
                                                  }}
                                                  fullWidth
                                                  size="small"
                                                  type="number"
                                                  placeholder="0.00"
                                                  name="receive"
                                                  value={(Math.round(state.receive * 100) / 100).toFixed(2)}
                                                  onChange={handleChangeText}
                                                  disabled={true}
                                             />
                                        </Grid>
                                   </>
                              }
                              {state.type === 5 && (
                                   <>
                                        <Grid item className="typography-profile pt-0" xs={4} sx={{ mt: 1 }}>
                                             <Typography>{language === "th" ? "Address" : "Address"}</Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                             {/* <List fullWidth>
                                                  {address_data.map((option) => (
                                                       <>
                                                            <ListItem key={option.value}>
                                                                 <ListItemButton onClick={() => copy(option.value)} display="flex" justifyContent="flex-start">
                                                                      <img src="/image/copy.svg" width={30} />
                                                                 </ListItemButton>
                                                                 <Typography >
                                                                      {option.label}
                                                                 </Typography>
                                                            </ListItem>
                                                       </>
                                                  ))}
                                             </List> */}
                                             {address_data.map((option) => (
                                                  <Stack>
                                                       <Typography sx={{ mt: 1 }}>{option.label} : </Typography>
                                                       <TextField
                                                            multiline
                                                            sx={{ fontSize: "9px" }}
                                                            key={option.value}
                                                            fullWidth
                                                            size="small"
                                                            type="text"
                                                            placeholder="Code"
                                                            name="code"
                                                            value={option.value}
                                                            disabled
                                                            InputProps={{
                                                                 endAdornment: (
                                                                      <InputAdornment position="end">
                                                                           <IconButton onClick={() => copy(option.value)}>
                                                                                <img src="/image/copy.svg" width={25} />
                                                                           </IconButton >
                                                                      </InputAdornment>
                                                                 ),
                                                            }}
                                                       />
                                                  </Stack>
                                             ))}
                                        </Grid>
                                        <Grid item className="typography-profile pt-0" xs={4}>
                                             <Typography>{language === "th" ? "อัปโหลด" : "Upload"}</Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                             <Button variant="text" component="label"
                                                  sx={{
                                                       border: 3,
                                                       borderStyle: "dashed",
                                                       minWidth: "100%",
                                                       maxHeight: "auto",
                                                       overflow: "hidden",
                                                       borderColor: "#a4a5cd"
                                                  }}
                                             >
                                                  <Grid>
                                                       {select_image.length !== 0 ?
                                                            <Box
                                                                 sx={{
                                                                      maxWidth: "auto",
                                                                      maxHeight: "auto",
                                                                      overflow: "hidden",
                                                                      display: "flex",
                                                                      justifyContent: "center",
                                                                      alignItems: "center",
                                                                      textAlign: "center",
                                                                 }}
                                                            >
                                                                 <img
                                                                      src={select_image}
                                                                      width="100%"
                                                                      height="100%"
                                                                 />
                                                            </Box>
                                                            :
                                                            <Typography>{language === "th" ? "เลือกรูปภาพ +" : "Choose Image +"}</Typography>
                                                       }
                                                       <input hidden onChange={handleChangeImage} files={select_image} type="file" accept=".png, .jpg, .jpeg , gif" />
                                                  </Grid>
                                             </Button>
                                        </Grid>
                                        {/* <Grid item xs={8}>
                                             <FormControl>
                                                  <RadioGroup
                                                       row
                                                       aria-labelledby="demo-row-radio-buttons-group-label"
                                                       name="row-radio-buttons-group"
                                                       value={value}
                                                       onChange={handleRadioChange}
                                                  >
                                                       <FormControlLabel value="TRC20" control={<Radio />} label="TRC20" />
                                                       <FormControlLabel value="ERC20" control={<Radio />} label="ERC20" />
                                                  </RadioGroup>
                                             </FormControl>
                                        </Grid> */}
                                        <Grid item xs={12}>
                                             {/* <FormControlLabel
                                                  name="agree_conditions"
                                                  control={<Checkbox color="primary" />}
                                                  label={language === "th" ? "ฉันเห็นด้วยกับข้อกำหนดและเงื่อนไข" : "I agree with the Terms and Conditions."}
                                                  // onChange={handleChangeAgree}
                                             /> */}
                                        </Grid>
                                        {/* <Grid item xs={12} container justifyContent="center" alignContent="flex-end" marginTop={10}>
                                             <Button
                                                  variant="contained"
                                                  // color="error"
                                                  style={{ backgroundColor: "#4589C6" }}
                                                  className="btn-style"
                                                  sx={{ maxWidth: 260 }}
                                                  // onClick={onClickSubmitDeposit}
                                                  // disabled={state.status_transaction === 2}
                                                  onClick={handleSubmit}
                                             >
                                                  {language === "th" ? "ตกลง" : "Submit"}
                                             </Button>
                                        </Grid> */}
                                   </>
                              )}

                              {/* <Grid item className="typography-profile pt-0" xs={4}>
                                   <Typography>{language === "th" ? "โค้ดส่วนลด" : "Prmotion Code"}</Typography>
                              </Grid>
                              <Grid item xs={8}>
                                   <TextField
                                        className="textfield-profile"
                                        // InputProps={{
                                        //      endAdornment: <InputAdornment position="end"></InputAdornment>,
                                        // }}
                                        fullWidth
                                        size="small"
                                        type="text"
                                        placeholder="Code"
                                        name="code"
                                        value={state.code}
                                        onChange={handleChangeText}
                                        disabled={false}
                                   />
                              </Grid> */}
                         </Grid>

                         {/* {state.type > 3 && (
                              <Grid item xs={12} container alignItems="center" direction="column" marginBottom={2}>
                                   <CardMedia className={classes.media} component="img" alt="Logo" image={slip_upload ? slip_upload : slip} />
                                   <Stack alignItems="center">
                                        <label htmlFor="contained-button-file" style={{ width: 200 }}>
                                             <Input accept="image/*" id="contained-button-file" type="file" onChange={props.uploadProfile} />
                                             <Button variant="outlined" color="error" component="span" className="btn-style" sx={{ mt: 2, maxWidth: 200 }}>
                                                  {language === "th" ? "อัพโหลดสลิป" : "Upload Slip"}
                                             </Button>
                                        </label>
                                   </Stack>
                              </Grid>
                         )} */}
                         {state.type !== 5 && (
                              <>
                                   <Grid item xs={12}>
                                        <FormControlLabel
                                             name="agree_conditions"
                                             control={<Checkbox color="primary" />}
                                             label={language === "th" ? "ฉันเห็นด้วยกับข้อกำหนดและเงื่อนไข" : "I agree with the Terms and Conditions."}
                                             onChange={handleChangeAgree}
                                        />
                                   </Grid>
                                   <Grid item xs={12} container justifyContent="center" alignContent="flex-end" marginTop={10}>
                                        <Button
                                             variant="contained"
                                             // color="error"
                                             style={{ backgroundColor: "#4589C6" }}
                                             className="btn-style"
                                             sx={{ maxWidth: 260 }}
                                             onClick={onClickSubmitDeposit}
                                             disabled={state.status_transaction === 2}
                                        >
                                             {state.status_transaction === 2 ? `${minutes}:${seconds}` : language === "th" ? "ตกลง" : "Submit"}
                                        </Button>
                                   </Grid>
                              </>
                         )}
                         {state.type === 5 && (
                              <>
                                   <Grid item xs={12} container justifyContent="center" alignContent="flex-end" marginTop={10}>
                                        <Button
                                             variant="contained"
                                             // color="error"
                                             style={{ backgroundColor: "#4589C6" }}
                                             className="btn-style"
                                             sx={{ maxWidth: 260 }}
                                             onClick={uploadSlipUSDT}
                                             disabled={state.status_transaction === 2}
                                        >
                                             {language === "th" ? "ตกลง" : "Submit"}
                                        </Button>
                                   </Grid>
                              </>
                         )}
                         {state.image_qrcode && state.type !== 5 && (
                              <Grid item xs={12} container alignItems="center" direction="column" marginBottom={2}>
                                   <CardMedia className={classes.media} component="img" alt="Logo" image={state.image_qrcode} />
                              </Grid>
                         )}
                         {state.type == 5 && (
                              <Grid item xs={12} container alignItems="center" direction="column" marginBottom={2}>
                                   <CardMedia className={classes.media} component="img" image={valueCrypto} />
                                   <Typography>{textC}</Typography>
                              </Grid>
                         )}
                    </Grid>

                    <Grid item xs={12} container direction="row" spacing={2}>
                         <Grid item xs={12}>
                              <Typography className={classes.fontStyleTitleBorder}>{language === "th" ? "ธุรกรรม" : "Transaction"}</Typography>
                         </Grid>
                         <Grid item xs={12}>
                              <Stack direction="row" alignItems="center" mt={2}>
                                   <Typography sx={{ fontSize: 20, fontWeight: 600, color: "#000", mr: 2, display: { xs: "none", md: "block" } }}>
                                        {language === "th" ? "ค้นหา" : "Search"}:
                                   </Typography>
                                   <Grid container xs={12} md={4} sx={{ width: "40vw", mr: 2 }}>
                                        <GridTextField
                                             data={textFiled.search_fn}
                                             state={state}
                                             language={language}
                                             handleChange={handleSearch}
                                             onKeyDown={(e) => {
                                                  if (e.key === "Enter") {
                                                       submitSearch();
                                                  }
                                             }}
                                        />
                                   </Grid>
                                   <Button
                                        variant="contained"
                                        className={classes.btnBlue}
                                        //  className="btn-style"
                                        sx={{ maxWidth: 120, fontSize: 16, width: "100%", borderRadius: 40, height: 40, display: { xs: "none", md: "block" } }}
                                        onClick={submitSearch}
                                   >
                                        {language === "th" ? "ค้นหา" : "Search"}
                                   </Button>
                                   <Button
                                        variant="contained"
                                        className={classes.btnBlue}
                                        //  className="btn-style"
                                        sx={{ maxWidth: 70, fontSize: 16, width: "100%", borderRadius: 40, height: 40, display: { xs: "block", md: "none" } }}
                                        onClick={submitSearch}
                                   >
                                        <SearchIcon />
                                   </Button>
                              </Stack>
                         </Grid>
                         <TableDeposit body={dataList} header={headerTable.deposit} onClickViewSlip={onClickViewSlip} onClickStatus={onClickStatus} />
                         {dataList.length === 0 && (
                              <Grid container item justifyContent="center" alignItems="center" xs={12} mt={2}>
                                   <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                        {props.language === "th" ? "ไม่มีข้อมูลในตาราง" : "No data available in table"}
                                   </Typography>
                              </Grid>
                         )}
                         {data.length > 0 && (
                              <Grid container item justifyContent="center" alignItems="center" xs={12} mt={2}>
                                   {props.data.length > 0 && (
                                        <Pagination count={count} page={page} onChange={handleChangePage} variant="outlined" color="secondary" hidePrevButton hideNextButton />
                                   )}
                              </Grid>
                         )}
                    </Grid>
               </Grid>
          </Grid>
     );
};
const Input = styled("input")({
     display: "none",
});
const useStyles = makeStyles(() => ({
     imgStyle: {
          height: 230,
          [theme.breakpoints.up("sm")]: {
               height: 260,
          },
     },
     iconStyle: {
          height: 25,
          width: "auto",
          marginRight: 10,
          [theme.breakpoints.up("sm")]: {
               height: 40,
          },
     },
     fontStyleTitle: {
          fontSize: 24,
          color: "#000",
          textAlign: "left",
          fontWeight: 600,
          marginTop: 16,
          paddingLeft: 16,
          paddingRight: 16,
     },
     fontStyleTitleBorder: {
          fontSize: 24,
          color: "#000",
          textAlign: "left",
          fontWeight: 600,
          marginTop: 16,
          paddingLeft: 16,
          paddingRight: 16,
          borderBottom: "solid 2px #4589C6",
          marginBottom: 10,
     },
     fontStyleDetail: {
          fontSize: 13,
          color: "#000",
          textAlign: "justify",
          paddingLeft: 16,
          paddingRight: 16,
          [theme.breakpoints.up("sm")]: {
               fontSize: 18,
          },
     },
     fontStyleFooter: {
          fontSize: 10,
          color: "#000",
          [theme.breakpoints.up("sm")]: {
               fontSize: 14,
          },
     },
     gridRadio: {
          border: "solid 1px #ebebeb",
          marginBottom: 10,
     },
     media: {
          width: 300,
          height: "auto",
          paddingTop: 10,
     },
     btnBlue: {
          backgroundColor: "#4589C6",
     },
}));
export default withRouter(WebDeposit);
