import React from "react";
import moment from "moment";

import { Line } from "react-chartjs-2";

export default function ChartCommission({ data }) {

    const commission = {
        labels: data.map((e) => moment(e.created_at).format("DD/MM/YYYY")),
        datasets: [
            {
                label: "Rebate",
                data: data.map((e) => e.money),
                fill: true,
                backgroundColor: "rgba(75,192,192,0.2)",
                borderColor: "rgba(75,192,192,1)"
            },
        ]
    };

    return (
        <div>
            <Line data={commission} />
        </div>
    );
}
