import { Container } from "@material-ui/core";
import LoadingPage from "components/LoadingPage/LoadingPage";
import moment from "moment";
import { Component } from "react";
import { withRouter } from "react-router-dom";
import { GETALLIBNEST, GETIB, GETIBALL, GETIBALLNEST, GETIBHEADER, GETIBSELECTNEST, POST } from "service";
import swal from "sweetalert2";
import ListIBNest from "./component/ListIBNest";

class IBListNest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            IB: [],
            name: "",
            dataSub: [],
            network: [],
            loading: false,
            dataTabel: [],
            accountType: null,
            nav_sub_ib: [],
            count_network: [],
            date_start: new Date(),
            date_end: new Date(),
            data_header: [],
            data_header_profile: [],
            user_id: this.props.match.params.id,
            count_page: 0,
            data_ib_nest: [],
            showAll: false,
        };
    }

    componentDidMount = () => {
        this.getIB();
        this.getHeader()
        this.getIBNest();
    };

    getIBNest = async () => {
        this.setState({ data_ib_nest: [] })
        let { date_start, date_end } = this.state;
        let date_start_format = moment(date_start).format("YYYY-MM-DD");
        let date_end_format = moment(date_end).format("YYYY-MM-DD");
        try {
            // this.setState({ loading: true });
            let res = await POST(GETIBALLNEST, { date_start: date_start_format, date_end: date_end_format });
            if (res.success) {
                this.setState({ data_ib_nest: res.result })
                // this.setState({ loading: false });
            }
        } catch (error) {
            // this.setState({ loading: false });
            console.log(error)
        }
    }

    getIB = async () => {
        const { date_end, date_start } = this.state;
        let date_start_format = moment(date_start).format("YYYY-MM-DD");
        let date_end_format = moment(date_end).format("YYYY-MM-DD");
        try {
            this.setState({ loading: true });
            let getIB = await POST(GETIB, { date_start: date_start_format, date_end: date_end_format });
            if (getIB.success) {
                if (getIB.result.length > 0) {
                    this.setState({ IB: getIB.result });
                } else {
                    this.setState({ data_member: getIB.result });
                }
                this.setState({ loading: false });
            } else {
                this.setState({ loading: false });
            }
        } catch (error) {
            this.setState({ loading: false });
            swal.fire({
                title: "Warning", text: error, icon: "warning",
                // timer: 6000, 
                showConfirmButton: true
            });
        }
    };

    getAllIBNest = async () => {
        let { date_start, date_end } = this.state;
        let date_start_format = moment(date_start).format("YYYY-MM-DD");
        let date_end_format = moment(date_end).format("YYYY-MM-DD");
        try {
            let res = await POST(GETALLIBNEST, { date_start: date_start_format, date_end: date_end_format });
            if (res.success) {
                // console.log(res.result);
                this.setState({ data_ib_nest: res.result })
            } else {
                swal.fire({
                    title: "ไม่สำเร็จ", text: res.message, icon: "warning",
                    showConfirmButton: true
                });
            }
        } catch (error) {
            swal.fire({
                title: "ไม่สำเร็จ", text: error, icon: "warning",
                showConfirmButton: true
            });
        }
    }

    getHeader = async () => {
        let { date_start, date_end } = this.state;
        let date_start_format = moment(date_start).format("YYYY-MM-DD");
        let date_end_format = moment(date_end).format("YYYY-MM-DD");
        try {
            let amount = await POST(GETIBHEADER, { date_start: date_start_format, date_end: date_end_format });
            if (amount.success) {
                this.setState({ data_header: amount.result })
            }
        } catch (error) {
            console.log(error)
        }
    }

    handleDataNest = async (user_id) => {
        let { date_start, date_end } = this.state;
        let date_start_format = moment(date_start).format("YYYY-MM-DD");
        let date_end_format = moment(date_end).format("YYYY-MM-DD");
        try {
            let res = await POST(GETIBSELECTNEST, { date_start: date_start_format, date_end: date_end_format, user_id });
            if (res.success) {
                this.updateChild(user_id, res.result);
            }
        } catch (error) {
            console.log(error)
        }
    }

    handleChangeText = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    submitSearch = async () => {
        this.setState({ data_header: [], showAll: false })
        let { date_start, date_end } = this.state;
        let date_start_format = moment(date_start).format("YYYY-MM-DD");
        let date_end_format = moment(date_end).format("YYYY-MM-DD");
        this.getIBNest();
        try {
            this.setState({ loading: true, page: 1 });
            let getIB = await POST(GETIBALL, { date_start: date_start_format, date_end: date_end_format, page: 1 });
            if (getIB.success) {
                this.getHeader();
                this.setState({ network: getIB.result.ib_data, IB: getIB.result.ib_data, count_page: getIB.result.count });
                this.setState({ loading: false });
            } else {
                this.setState({ loading: false });
            }
        } catch (error) {
            swal.fire({ title: "warning", text: error, icon: "warning", showConfirmButton: true });
        }
    };

    handleDateStartChange = (date) => {
        this.setState({ date_start: date });
    };

    handleDateEndChange = (date) => {
        this.setState({ date_end: date });
    };

    handleChangePage = (e, value) => {
        this.setState({ page: value });
    };

    updateChild = (userId, newChildData) => {
        const { data_ib_nest } = this.state;
        const updateUser = (users) => {
            return users.map(user => {
                if (user.user_id === userId) {
                    return { ...user, ...newChildData };
                }

                if (user.children.length > 0) {
                    return { ...user, children: updateUser(user.children) };
                }

                return user;
            });
        };

        this.setState({ data_ib_nest: updateUser(data_ib_nest) })
    };

    handleShowAll = () => {
        const { showAll } = this.state;
        this.setState({ showAll: !showAll })
    };

    render() {
        const { loading, data, profile, language, page, data_ib_nest, data_header, showAll } = this.state;
        return (
            <Container maxWidth="xl" sx={{ position: "relative" }}>
                <LoadingPage open={loading} />
                <ListIBNest
                    state={this.state}
                    language={language}
                    data={data}
                    profile={profile}
                    page={page}
                    showAll={showAll}
                    handleChangePage={this.handleChangePage}
                    submitSearch={this.submitSearch}
                    handleDateStartChange={this.handleDateStartChange}
                    handleDateEndChange={this.handleDateEndChange}
                    data_ib_nest={data_ib_nest}
                    data_header={data_header}
                    handleDataNest={this.handleDataNest}
                    getAllIBNest={this.getAllIBNest}
                    getIBNest={this.getIBNest}
                    handleShowAll={this.handleShowAll}
                />
            </Container>
        );
    }
}

export default withRouter(IBListNest);
