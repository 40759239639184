import { Button, Container, Grid, Typography } from "@material-ui/core";
import { createMuiTheme } from "@material-ui/core/styles";
import { GridTextField } from "components";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import '../../../css/index.scss';
const theme = createMuiTheme();

const TapCrypto = (props) => {
    const { language, handleChangeText, inputValue, handleSubmit, data, menuItem, profile } = props;

    const classes = useStyles();

    return (
        <Grid>
            <Grid mt={5} container justifyContent="center" alignItems="center" rowSpacing={2}>
                <Grid item xs={12}>
                    <Grid item container justifyContent="center" xs={12} marginTop={2}>
                        <Grid item container spacing={1} xs={12} md={6}>
                            <GridTextField data={data} state={inputValue} language={language} handleChange={handleChangeText} menuItem={menuItem} />
                            <Typography className={classes.fontStyleDetail} my={3}>
                                {language === "th" ?
                                    "กรณีลูกค้าต้องการถอนเงินออกเป็น USDT ลูกค้าต้องมีการยืนยันบัญชีธนาคารกับ QRS Global และลูกค้าต้องทำการฝากเข้ามาเป็น USDT ก่อน ถึงทำการถอนเงินออกเป็น USDT ได้ เพื่อเป็นการ KYC และปฎิบัติตามมาตรการป้องกันการฟอกเงิน"
                                    :
                                    "In the case where a client wishes to withdraw funds as USDT, the client must verify their bank account with QRS Global and must have made a deposit in USDT before being able to withdraw funds in USDT. This is required for KYC purposes and to comply with anti-money laundering measures."
                                }
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} mt={5}>
                    <Container maxWidth="sm">
                        <Grid container textAlign="center" columnSpacing={1} rowSpacing={3} justifyContent="center">
                            <Grid item xs={12} sm={6}>
                                <Button
                                    variant="contained"
                                    style={{ backgroundColor: "#4589C6" }}
                                    className="btn-style" sx={{ maxWidth: 260 }}
                                    onClick={handleSubmit}
                                    disabled={profile.crypto_type && profile.address_usdt}
                                >
                                    {language === "th" ? "บันทึก" : "Svae"}
                                </Button>
                            </Grid>
                        </Grid>
                    </Container>
                </Grid>
            </Grid>
        </Grid>
    );
};

const useStyles = makeStyles(() => ({
    fontStyleDetail: {
        fontSize: 12,
        color: "#676767",
        textAlign: "justify",
        marginTop: 10,
        [theme.breakpoints.up("md")]: {
            fontSize: 14,
        },
        textIndent: "30px",
    },
}));

export default withRouter(TapCrypto);
