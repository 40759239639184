import { Grid, Typography, Paper, Box, Stack } from "@material-ui/core";
import { tofieds } from "functions/Functions";
import { BeatLoader } from "react-spinners";

const override = {
    display: "flex",
    justifyContent: 'center',
    alignItems: 'center',
};

const GridPaperIB = (props) => {

    const item = props.data;

    return (
        <Grid container spacing={2}>
            <Grid item container mb={2} mt={1} justifyContent="space-between" rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                {props.header.map((field, index) => (
                    <Grid item xs={12} sm={6} md={3}>
                        <Paper elevation={3} sx={{ width: "100%" }}>
                            <Grid container p={2} spacing={2}>
                                <Grid item xs={12}>
                                    <Typography sx={{ fontSize: 20, fontWeight: 600, color: "#0d47a1" }}>
                                        {field.label}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} display="flex" justifyContent="center">
                                    <Stack direction="row" spacing={1} width="100%" display="flex" justifyContent="center">
                                        {item[field.value] !== undefined ?
                                            <Typography sx={{ fontSize: 20, color: "#0d47a1" }}>
                                                {tofieds(item[field.value]) || "0.00"}
                                            </Typography>
                                            :
                                            <BeatLoader
                                                color="#0d47a1"
                                                loading={item[field.value] !== undefined ? false : true}
                                                cssOverride={override}
                                                size={5}
                                                aria-label="Loading Spinner"
                                                data-testid="loader"
                                            />
                                        }
                                        <Typography sx={{ fontSize: 20, color: "#0d47a1" }}>
                                            {field.suffix}
                                        </Typography>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                ))}
                {/* <Grid item xs={12} sm={6} md={3}>
                    <Paper elevation={3} sx={{ width: "100%" }}>
                        <Grid container p={2} spacing={2}>
                            <Grid item xs={12}>
                                <Typography sx={{ fontSize: 20, fontWeight: 600 }}>
                                    My Lot
                                </Typography>
                            </Grid>
                            <Grid item xs={12} display="flex" justifyContent="center">
                                <Typography sx={{ fontSize: 20 }}>{tofieds(Number(props.data.my_lot)) || "0.00"} Lot</Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Paper elevation={3} sx={{ width: "100%" }}>
                        <Grid container p={2} spacing={2}>
                            <Grid item xs={12}>
                                <Typography sx={{ fontSize: 20, fontWeight: 600 }}>
                                    Commission
                                </Typography>
                            </Grid>
                            <Grid item xs={12} display="flex" justifyContent="center">
                                <Typography sx={{ fontSize: 20 }}>{tofieds(Number(props.data.commission)) || "0.00"} $</Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Paper elevation={3} sx={{ width: "100%" }}>
                        <Grid container p={2} spacing={2}>
                            <Grid item xs={12}>
                                <Typography sx={{ fontSize: 20, fontWeight: 600 }}>
                                    Deposit
                                </Typography>
                            </Grid>
                            <Grid item xs={12} display="flex" justifyContent="center">
                                <Typography sx={{ fontSize: 20 }}>{tofieds(Number(props.data.deposit)) || "0.00"} $</Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Paper elevation={3} sx={{ width: "100%" }}>
                        <Grid container p={2} spacing={2}>
                            <Grid item xs={12}>
                                <Typography sx={{ fontSize: 20, fontWeight: 600 }}>
                                    Withdraw
                                </Typography>
                            </Grid>
                            <Grid item xs={12} display="flex" justifyContent="center">
                                <Typography sx={{ fontSize: 20 }}>{tofieds(Number(props.data.withdraw)) || "0.00"} $</Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Paper elevation={3} sx={{ width: "100%" }}>
                        <Grid container p={2} spacing={2}>
                            <Grid item xs={12}>
                                <Typography sx={{ fontSize: 20, fontWeight: 600 }}>
                                    Net Deposit
                                </Typography>
                            </Grid>
                            <Grid item xs={12} display="flex" justifyContent="center">
                                <Typography sx={{ fontSize: 20 }}>{tofieds(Number(props.data.net_deposit)) || "0.00"} $</Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Paper elevation={3} sx={{ width: "100%" }}>
                        <Grid container p={2} spacing={2}>
                            <Grid item xs={12}>
                                <Typography sx={{ fontSize: 20, fontWeight: 600 }}>
                                    Net Deposit Team
                                </Typography>
                            </Grid>
                            <Grid item xs={12} display="flex" justifyContent="center">
                                <Typography sx={{ fontSize: 20 }}>{tofieds(Number(props.data.net_deposit_team)) || "0.00"} $</Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Paper elevation={3} sx={{ width: "100%" }}>
                        <Grid container p={2} spacing={2}>
                            <Grid item xs={12}>
                                <Typography sx={{ fontSize: 20, fontWeight: 600 }}>
                                    Team Deposit
                                </Typography>
                            </Grid>
                            <Grid item xs={12} display="flex" justifyContent="center">
                                <Typography sx={{ fontSize: 20 }}>{tofieds(Number(props.data.team_deposit)) || "0.00"} $</Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Paper elevation={3} sx={{ width: "100%" }}>
                        <Grid container p={2} spacing={2}>
                            <Grid item xs={12}>
                                <Typography sx={{ fontSize: 20, fontWeight: 600 }}>
                                    Team Lot
                                </Typography>
                            </Grid>
                            <Grid item xs={12} display="flex" justifyContent="center">
                                <Typography sx={{ fontSize: 20 }}>{tofieds(Number(props.data.team_lot)) || "0.00"} Lot</Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid> */}
            </Grid>
        </Grid>
    );
};

export default GridPaperIB;
