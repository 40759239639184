import React from "react";
import clsx from "clsx";
import { Fade, Modal, Backdrop, Grid, Button, Typography, TextField, InputAdornment, FormControl, InputLabel, OutlinedInput, FormHelperText } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import CloseIcon from "@material-ui/icons/Close";
import CircularProgress from "@material-ui/core/CircularProgress";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";

const ModalEditTrading = (props) => {
     const { data, open, handleCloseModal, state, language, handleChange, loading, type_edit, onClickChangePassword, error, error_massage } = props;
     const classes = useStyles();
     const [values, setValues] = React.useState({
          showPassword: false,
          showInvesterPassword: false,
          confirm: false,
     });

     const handleClickShowPassword = () => {
          setValues({ ...values, showPassword: !values.showPassword });
     };

     const handleClickShowConfirm = () => {
          setValues({ ...values, confirm: !values.confirm });
     };

     const handleClickShowInvesterPassword = () => {
          setValues({ ...values, showInvesterPassword: !values.showInvesterPassword });
     };

     const handleMouseDownPassword = (event) => {
          event.preventDefault();
     };
     return (
          <Modal
               aria-labelledby="transition-modal-title"
               aria-describedby="transition-modal-description"
               open={open}
               onClose={handleCloseModal}
               closeAfterTransition
               BackdropComponent={Backdrop}
               BackdropProps={{
                    timeout: 500,
               }}
          >
               <Fade in={open}>
                    <Grid className={classes.boxStyle}>
                         <Grid container rowSpacing={3} justifyContent="center" alignItems="center">
                              <Grid container justifyContent="flex-end" xs={12}>
                                   <Button onClick={() => handleCloseModal()} className={classes.buttonClose}>
                                        <CloseIcon fontSize="small" style={{ color: "#3b3b3b" }} />
                                   </Button>
                              </Grid>
                              <Typography sx={{ fontSize: 18 }}>{type_edit === "trader" ? "Change trade password" : "Change investor password"}</Typography>

                              <Grid item xs={12} spacing={2}>
                                   {type_edit === "trader" ? (
                                        <Grid item container spacing={2} justifyContent={"center"}>
                                             <Grid item xs={12} container alignItems="center">
                                                  <Typography sx={{ fontSize: 14, fontWeight: 600 }}>Password</Typography>
                                             </Grid>
                                             <Grid item xs={12} container justifyContent={"center"} alignItems="center">
                                                  <FormControl error={error} variant="outlined" sx={{ width: "100%" }}>
                                                       <OutlinedInput
                                                            id="outlined-adornment-password"
                                                            type={values.showPassword ? "text" : "password"}
                                                            value={state.trader_password}
                                                            onChange={handleChange("trader_password")}
                                                            endAdornment={
                                                                 <InputAdornment position="end">
                                                                      <IconButton
                                                                           aria-label="toggle password visibility"
                                                                           onClick={handleClickShowPassword}
                                                                           onMouseDown={handleMouseDownPassword}
                                                                           edge="end"
                                                                      >
                                                                           {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                                                      </IconButton>
                                                                 </InputAdornment>
                                                            }
                                                            labelWidth={70}
                                                       />
                                                       <FormHelperText id="component-error-text" >
                                                            {error_massage &&
                                                                 <Typography className={classes.msgDetailError}>
                                                                      {error_massage}
                                                                 </Typography>
                                                            }
                                                       </FormHelperText>
                                                  </FormControl>
                                             </Grid>
                                             <Grid item xs={12} container alignItems="center">
                                                  <Typography sx={{ fontSize: 14, fontWeight: 600 }}>Confirm</Typography>
                                             </Grid>
                                             <Grid item xs={12} container justifyContent={"center"} alignItems="center">
                                                  <FormControl error={error} variant="outlined" sx={{ width: "100%" }}>
                                                       <OutlinedInput
                                                            id="outlined-adornment-password"
                                                            type={values.confirm ? "text" : "password"}
                                                            value={state.confirm}
                                                            onChange={handleChange("confirm")}
                                                            endAdornment={
                                                                 <InputAdornment position="end">
                                                                      <IconButton
                                                                           aria-label="toggle password visibility"
                                                                           onClick={handleClickShowConfirm}
                                                                           onMouseDown={handleMouseDownPassword}
                                                                           edge="end"
                                                                      >
                                                                           {values.confirm ? <Visibility /> : <VisibilityOff />}
                                                                      </IconButton>
                                                                 </InputAdornment>
                                                            }
                                                            labelWidth={70}
                                                       />
                                                       <FormHelperText id="component-error-text" >
                                                            {error_massage &&
                                                                 <Typography className={classes.msgDetailError}>
                                                                      {error_massage}
                                                                 </Typography>
                                                            }
                                                       </FormHelperText>
                                                  </FormControl>
                                             </Grid>
                                             <Grid item xs={12}>
                                                  <Typography className={classes.msgTitle}>
                                                       {language === "th" ?
                                                            "เงื่อนไขการตั้งรหัสผ่าน"
                                                            :
                                                            "Password requirements"
                                                       }
                                                  </Typography>
                                                  <Typography className={classes.msgDetail}>
                                                       {language === "th" ?
                                                            "• โปรดตั้งรหัสผ่านความยาว 8 ตัวอักษรขึ้นไป ประกอบไปด้วยตัวอักษรพิมพ์ใหญ่ พิมพ์เล็ก ตัวเลข และสัญลักษณ์พิเศษ เช่น !,@,#,$,*"
                                                            :
                                                            "• Please set a password that is at least 8 characters long, containing uppercase letters, lowercase letters, numbers, and special characters such as !, @, #, $, *."
                                                       }
                                                  </Typography>
                                             </Grid>
                                        </Grid>
                                   ) : (
                                        <Grid item container spacing={2} justifyContent={"center"}>
                                             <Grid item xs={12} container alignItems="center">
                                                  <Typography sx={{ fontSize: 14, fontWeight: 600 }}>Invester Password</Typography>
                                             </Grid>
                                             <Grid item xs={12} container justifyContent={"center"} alignItems="center">
                                                  <FormControl error={error} variant="outlined" sx={{ width: "100%" }}>
                                                       <OutlinedInput
                                                            id="outlined-adornment-password"
                                                            type={values.showInvesterPassword ? "text" : "password"}
                                                            value={state.invester_password}
                                                            onChange={handleChange("invester_password")}
                                                            endAdornment={
                                                                 <InputAdornment position="end">
                                                                      <IconButton
                                                                           aria-label="toggle password visibility"
                                                                           onClick={handleClickShowInvesterPassword}
                                                                           onMouseDown={handleMouseDownPassword}
                                                                           edge="end"
                                                                      >
                                                                           {values.showInvesterPassword ? <Visibility /> : <VisibilityOff />}
                                                                      </IconButton>
                                                                 </InputAdornment>
                                                            }
                                                            labelWidth={70}
                                                       />
                                                       <FormHelperText id="component-error-text">
                                                            {error_massage &&
                                                                 <Typography className={classes.msgDetailError}>
                                                                      {error_massage}
                                                                 </Typography>
                                                            }
                                                       </FormHelperText>
                                                  </FormControl>
                                             </Grid>
                                             <Grid item xs={12} container alignItems="center">
                                                  <Typography sx={{ fontSize: 14, fontWeight: 600 }}>Confirm</Typography>
                                             </Grid>
                                             <Grid item xs={12} container justifyContent={"center"} alignItems="center">
                                                  <FormControl error={error} variant="outlined" sx={{ width: "100%" }}>
                                                       <OutlinedInput
                                                            id="outlined-adornment-password"
                                                            type={values.confirm ? "text" : "password"}
                                                            value={state.confirm}
                                                            onChange={handleChange("confirm")}
                                                            endAdornment={
                                                                 <InputAdornment position="end">
                                                                      <IconButton
                                                                           aria-label="toggle password visibility"
                                                                           onClick={handleClickShowConfirm}
                                                                           onMouseDown={handleMouseDownPassword}
                                                                           edge="end"
                                                                      >
                                                                           {values.confirm ? <Visibility /> : <VisibilityOff />}
                                                                      </IconButton>
                                                                 </InputAdornment>
                                                            }
                                                            labelWidth={70}
                                                       />
                                                       <FormHelperText id="component-error-text">
                                                            {error_massage &&
                                                                 <Typography className={classes.msgDetailError}>
                                                                      {error_massage}
                                                                 </Typography>
                                                            }
                                                       </FormHelperText>
                                                  </FormControl>
                                             </Grid>
                                             <Grid item xs={12}>
                                                  <Typography className={classes.msgTitle}>
                                                       {language === "th" ?
                                                            "เงื่อนไขการตั้งรหัสผ่าน"
                                                            :
                                                            "Password requirements"
                                                       }
                                                  </Typography>
                                                  <Typography className={classes.msgDetail}>
                                                       {language === "th" ?
                                                            "• โปรดตั้งรหัสผ่านความยาว 8 ตัวอักษรขึ้นไป ประกอบไปด้วยตัวอักษรพิมพ์ใหญ่ พิมพ์เล็ก ตัวเลข และสัญลักษณ์พิเศษ เช่น !,@,#,$,*"
                                                            :
                                                            "• Please set a password that is at least 8 characters long, containing uppercase letters, lowercase letters, numbers, and special characters such as !, @, #, $, *."
                                                       }
                                                  </Typography>
                                             </Grid>
                                        </Grid>
                                   )}
                                   <Grid item container spacing={2} justifyContent={"center"} mt={2}>
                                        <Button
                                             variant="contained"
                                             // color="error"
                                             style={{ backgroundColor: "#4589C6" }}
                                             onClick={async () => {
                                                  await onClickChangePassword(type_edit);
                                             }}
                                             disabled={loading}
                                             sx={{ m: 1, mt: 2, minWidth: 130 }}
                                        >
                                             {loading ? <CircularProgress size={25} style={{ color: "#fc0316", backgroundColor: "#4589C6" }} /> : `${language === "th" ? "ตกลง" : "Submit"}`}
                                        </Button>
                                        <Button
                                             variant="outlined"
                                             color="error"
                                             onClick={() => {
                                                  handleCloseModal();
                                             }}
                                             disabled={loading}
                                             sx={{ m: 1, mt: 2, minWidth: 130 }}
                                        >
                                             {language === "th" ? "ยกเลิก" : "Cancel"}
                                        </Button>
                                   </Grid>
                              </Grid>
                         </Grid>
                    </Grid>
               </Fade>
          </Modal>
     );
};

const useStyles = makeStyles((theme) => ({
     boxStyle: {
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "100%",
          maxWidth: "820px",
          minWidth: "280px",
          bgcolor: "#707070",
          background: "#fff",
          boxShadow: "rgb(0 0 0 / 20%) 0px 11px 15px -7px, rgb(0 0 0 / 14%) 0px 24px 38px 3px, rgb(0 0 0 / 12%) 0px 9px 46px 8px",
          padding: "40px 18px",
          borderRadius: "8px",
          marginTop: "20px",
     },
     buttonClose: {
          backgroundColor: "Transparent",
          backgroundRepeat: "no-repeat",
          border: "none",
          cursor: "pointer",
          overflow: "hidden",
          "&:hover": {
               backgroundColor: "Transparent",
          },
     },
     media: {
          width: "100%",
          height: "500px",
     },
     textHeader: {
          fontSize: 14,
          color: "#707070",
     },
     textDetail: {
          fontSize: 17,
          marginBottom: 20,
     },
     inputStyle: {
          border: "none",
          width: "-webkit-fill-available",
     },
     msgTitle: {
          fontSize: 14,
          color: "#676767",
          textAlign: "justify",
          marginTop: 5,
     },
     msgDetail: {
          fontSize: 14,
          color: "#676767",
          textAlign: "justify",
          marginTop: 5,
          textIndent: "15px"
     },
     msgDetailError: {
          fontSize: 12,
          color: "#e53935",
          textAlign: "justify",
          marginTop: 5,
     },
}));

export default ModalEditTrading;
